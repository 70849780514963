import { RECAPTCHA_KEY_V3 } from '@/constants/config';
import { EGoogleRecaptchaVersion } from '@/constants/enum';

const _grecaptcha = window.grecaptcha;

let v2RecaptchaToken: string = '';

export const grecaptchaExecute = (action: string) => {
  return new Promise<{
    token: string;
    version: EGoogleRecaptchaVersion;
  }>((resolve, reject) => {
    if (v2RecaptchaToken) {
      resolve({ token: v2RecaptchaToken, version: EGoogleRecaptchaVersion.V2 });
    } else {
      _grecaptcha.ready(async () => {
        try {
          const token = await _grecaptcha.execute(RECAPTCHA_KEY_V3, { action });
          resolve({ token, version: EGoogleRecaptchaVersion.V3 });
        } catch (error) {
          reject(error);
        }
      });
    }
  }).catch(() => {
    return {
      token: '',
      version: EGoogleRecaptchaVersion.V2,
    };
  });
};

export const setV2RecaptchaToken = (token: string) => {
  v2RecaptchaToken = token;
};
