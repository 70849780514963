import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { TRootState, useAppDispatch } from '@/store';
import { nextState } from '@/store/offrampSlice';

import StepsSection, { IStepsSectionProps } from './section';
import { TBreezeStep, useSteps } from './useSteps';

export const enum EOfframp2Step {
  OVERVIEW = 'OVERVIEW',
  KYC = 'KYC',
  BANK = 'BANK',
  SUBMIT = 'SUBMIT',
}

export const offrampFlowSteps = [EOfframp2Step.OVERVIEW, EOfframp2Step.KYC, EOfframp2Step.BANK, EOfframp2Step.SUBMIT];

export const useOfframp2Steps = (step: TBreezeStep) => {
  const activeStep = useSelector((state: TRootState) => state.offramp.activeStep);
  const dispatch = useAppDispatch();

  const steps = offrampFlowSteps;

  const { activeIndex, ...conditions } = useSteps({
    step,
    activeStep,
    steps,
  });

  const nextStep = useCallback(
    (newStep?: TBreezeStep) => {
      let next = newStep;
      if (!newStep && activeIndex < steps.length - 1) {
        next = steps[activeIndex + 1];
      }
      if (next) {
        dispatch(nextState({ activeStep: next }));
      }
    },
    [activeIndex, dispatch, steps]
  );

  return {
    steps,
    nextStep,
    activeStep,
    ...conditions,
  };
};

export const Offramp2StepsSection = ({ step, ...props }: Omit<IStepsSectionProps, 'activeStep' | 'steps'>) => {
  const { steps, activeStep } = useOfframp2Steps(step);

  return <StepsSection activeStep={activeStep} steps={steps} step={step} {...props} />;
};
