import { CHAIN_NAMESPACES, CustomChainConfig } from '@web3auth/base';

import { EMode } from './enum';

export const enum ECryptoNetworkSymbol {
  ETHEREUM = 'ETHEREUM',
  OPTIMISM = 'OPTIMISM',
  BINANCE = 'BINANCE',
  POLYGON = 'POLYGON',
  ARBITRUM = 'ARBITRUM',
  AVALANCHE = 'AVALANCHE',
  SOLANA = 'SOLANA',
  TRON = 'TRON',
}

export enum ECryptoNetworkId {
  ETHEREUM = 1,
  OPTIMISM = 10,
  BINANCE = 56,
  POLYGON = 137,
  ARBITRUM = 42161,
  AVALANCHE = 43114,
  SOLANA = -1,
}

export enum EChainId {
  Mainnet = 1,
  Optimism = 10,
  ArbitrumOne = 42161,
  PolygonMainnet = 137,
  AvalancheCChain = 43114,
  BinanceSmartChainMainnet = 56,
  Invalid = -1,
}

export const SOLANA_MAIN_NET_URL = 'https://mainnet.helius-rpc.com/?api-key=cc508f03-462d-4579-b8b3-3589c84ebe0d';
//  'https://intensive-weathered-thunder.solana-mainnet.quiknode.pro/0d619639154b8a1bcf055050d0eb5c9eda78743c/';
export const SOLANA_PUBLIC_MAIN_NET_URLS = ['https://rpc.ankr.com/solana'];
export const SOLANA_DEV_NET_URL =
  'https://warmhearted-fragrant-season.solana-devnet.quiknode.pro/cdabe334396b3ecfd659e652f0fc85a7d71d5a1e/';

// TODO: we shall find proper rpcTarget here, especially for Ethereum as infura may has quota limit
// -- frontend cannot share the same rpcTarget with backend
export const CHAIN_CONFIG_LIVE: { [K in ECryptoNetworkSymbol]?: CustomChainConfig & { blockExplorer: string } } = {
  [ECryptoNetworkSymbol.ETHEREUM]: {
    displayName: 'Ethereum',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1',
    rpcTarget: 'https://eth-mainnet.g.alchemy.com/v2/_0YBr4PPhV9GcflHyRVtkMKuG9ATbzX0',
    blockExplorer: 'https://etherscan.io/',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  },
  [ECryptoNetworkSymbol.SOLANA]: {
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    rpcTarget: SOLANA_MAIN_NET_URL, // dedicated helius frontend node without TPS limit
    blockExplorer: 'https://solscan.io/',
    chainId: '0x1',
    displayName: 'Solana',
    ticker: 'SOL',
    tickerName: 'Solana',
  },
  [ECryptoNetworkSymbol.POLYGON]: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://polygon-mainnet.g.alchemy.com/v2/uutge11ysbHOLnvY26SineIr7O_1mYkx',
    blockExplorer: 'https://polygonscan.com/',
    chainId: '0x89',
    displayName: 'Polygon',
    ticker: 'MATIC',
    tickerName: 'Matic',
  },
  [ECryptoNetworkSymbol.BINANCE]: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://misty-winter-research.bsc.quiknode.pro/cec039d9a5415cffe34506c5672ed86d887bdc64/',
    blockExplorer: 'https://bscscan.com/',
    chainId: '0x38',
    displayName: 'BNB Chain',
    ticker: 'BNB',
    tickerName: 'BNB',
  },
  [ECryptoNetworkSymbol.AVALANCHE]: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget:
      'https://crimson-thrumming-research.avalanche-mainnet.quiknode.pro/953394dab00cb8fce5b9a3a039d5d444bf7e4491/ext/bc/C/rpc',
    blockExplorer: 'https://snowtrace.io/',
    chainId: '0xA86A',
    displayName: 'Avalanche',
    ticker: 'AVAX',
    tickerName: 'Avalanche',
  },
  [ECryptoNetworkSymbol.ARBITRUM]: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://arb-mainnet.g.alchemy.com/v2/yYII3VJtoICkMGCAt4DvkKqS_yYGZh-G',
    blockExplorer: 'https://arbiscan.io/',
    chainId: '0xA4B1',
    displayName: 'Arbitrum',
    ticker: 'AETH',
    tickerName: 'AETH',
  },
  [ECryptoNetworkSymbol.OPTIMISM]: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://opt-mainnet.g.alchemy.com/v2/IJ-FDkSljKfy3Dqb4xgiTbGG9AdgmkXs',
    blockExplorer: 'https://optimistic.etherscan.io/',
    chainId: '0xA',
    displayName: 'Optimism',
    ticker: 'OP',
    tickerName: 'OP',
  },
};

export const CHAIN_CONFIG_TEST: { [K in ECryptoNetworkSymbol]?: CustomChainConfig & { blockExplorer: string } } = {
  [ECryptoNetworkSymbol.ETHEREUM]: {
    displayName: 'Sepolia Testnet',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0xaa36a7',
    rpcTarget: 'https://rpc.sepolia.org',
    blockExplorer: 'https://sepolia.etherscan.io/',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  },
  [ECryptoNetworkSymbol.SOLANA]: {
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    rpcTarget: SOLANA_DEV_NET_URL,
    blockExplorer: 'https://solscan.io/',
    chainId: '0x2',
    displayName: 'Solana Testnet',
    ticker: 'SOL',
    tickerName: 'Solana',
  },
};

export const CHAIN_CONFIG = {
  [EMode.LIVE]: CHAIN_CONFIG_LIVE,
  [EMode.TEST]: CHAIN_CONFIG_TEST,
};

export const NFT_SUPPORTED_CHAINS = [ECryptoNetworkSymbol.SOLANA];

export const cryptoNetworkIdToName = {
  [ECryptoNetworkId.ETHEREUM]: ECryptoNetworkSymbol.ETHEREUM,
  [ECryptoNetworkId.OPTIMISM]: ECryptoNetworkSymbol.OPTIMISM,
  [ECryptoNetworkId.BINANCE]: ECryptoNetworkSymbol.BINANCE,
  [ECryptoNetworkId.POLYGON]: ECryptoNetworkSymbol.POLYGON,
  [ECryptoNetworkId.ARBITRUM]: ECryptoNetworkSymbol.ARBITRUM,
  [ECryptoNetworkId.AVALANCHE]: ECryptoNetworkSymbol.AVALANCHE,
  [ECryptoNetworkId.SOLANA]: ECryptoNetworkSymbol.SOLANA,
};
