import { OAuthCredential } from 'firebase/auth';
import Cookies from 'universal-cookie';

import { ALLOWED_DOMAINS, BEAMO_CONNECT_TOKEN } from '@/constants/config';
import { isDev, isSubdomainOf } from '@/utils';

const cookies = new Cookies();

const getCurrentDomain = () =>
  '.' + ALLOWED_DOMAINS.find((domain) => isSubdomainOf(window.location.host, domain)) || '';

export const LOGOUT_MARK = 'logout';
export const getUniversalCookie = (): string | OAuthCredential | null => {
  const cookie = cookies.get(BEAMO_CONNECT_TOKEN);
  try {
    // oauth credential
    return OAuthCredential.fromJSON(cookie);
  } catch {
    // custom token
    return cookie;
  }
};

export const setUniversalCookie = (token = '') => {
  const params = isDev ? { path: '/' } : { path: '/', domain: getCurrentDomain() };
  cookies.set(BEAMO_CONNECT_TOKEN, token, params);
};

export const clearUniversalCookie = () => {
  const params = isDev ? { path: '/' } : { path: '/', domain: getCurrentDomain() };
  cookies.set(BEAMO_CONNECT_TOKEN, LOGOUT_MARK, params);
};
