import { isDev } from '@/utils';

const deployTag = process.env.REACT_APP_DEPLOY_TAG || 'devdp';
export const ALLOWED_DOMAINS = isDev
  ? ['localhost']
  : deployTag === 'prod'
    ? ['breeze.cash']
    : [`${deployTag}.breeze.cash`];

export const BEAMO_CONNECT_TOKEN = `__beamo_connect_token_${deployTag}`;

export const livemodeStorageKey = `__beamo_livemode_${deployTag}`;

const WEB3_AUTH_CONFIG = {
  qa: {
    clientId: 'BGeuZOXQQw3OFKI0PqJ98h5xemqCySOs10b148PO9MZycZ2jIp4uJ0DMVhc5XoxFkZqyVpqhYWa5Li_mN3bHA-g',
    verifier: 'beamo-firebase-qa',
    network: 'cyan',
  },
  devdp: {
    clientId: 'BJO_lv2CCkH4kT0ns_RstORcMXxoaOBf-uj_xO95cIX-DQtCZS0AhXl_UW2RbX78kIWKFm8axvxp0nhAHvMw92A',
    verifier: 'beamo-firebase-devdp',
    network: 'cyan',
  },
  prod: {
    clientId: 'BJjt8sRKjV-AnkcCg9HuRP5HmpmrYTDcOAIMULf1TmPqr6Zzh4Y1QMeRa1NLL-dKfQlo3OQgSETXHk-3jMO9mpQ',
    verifier: 'beamo-firebase-production',
    network: 'cyan',
  },
};

export function getWeb3AuthConfig() {
  return WEB3_AUTH_CONFIG[deployTag];
}

export const RECAPTCHA_KEY_V2 = '6Ld6GYglAAAAAHgV1lImJfSuhrdQVvUKdm8Nh1mP';
export const RECAPTCHA_KEY_V3 = '6LdMQQslAAAAAOmNJtL-zm8YtZYcIvn-_bDqri6D';
