/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

import { ECryptoNetworkSymbol } from './chains';
import { EMode } from './enum';

export const enum ECryptoCurrencySymbol {
  AVAX = 'AVAX',
  BNB = 'BNB',
  BTC = 'BTC',
  BUSD = 'BUSD',
  DAI = 'DAI',
  ETH = 'ETH',
  MATIC = 'MATIC',
  SOL = 'SOL',
  USDC = 'USDC',
  USDT = 'USDT',
  HONEY = 'HONEY',
  KAP = 'KAP',
}

const LIVE_MODE_ENABLED_TOKENS: TLiveModeEnabledTokens = {
  [ECryptoNetworkSymbol.ETHEREUM]: [
    ECryptoCurrencySymbol.BTC,
    ECryptoCurrencySymbol.BUSD,
    ECryptoCurrencySymbol.DAI,
    ECryptoCurrencySymbol.ETH,
    ECryptoCurrencySymbol.USDC,
    ECryptoCurrencySymbol.USDT,
    ECryptoCurrencySymbol.KAP,
  ],
  [ECryptoNetworkSymbol.OPTIMISM]: [
    ECryptoCurrencySymbol.BTC,
    ECryptoCurrencySymbol.DAI,
    ECryptoCurrencySymbol.ETH,
    ECryptoCurrencySymbol.USDC,
    ECryptoCurrencySymbol.USDT,
  ],
  [ECryptoNetworkSymbol.BINANCE]: [
    ECryptoCurrencySymbol.BTC,
    ECryptoCurrencySymbol.BUSD,
    ECryptoCurrencySymbol.BNB,
    ECryptoCurrencySymbol.DAI,
    ECryptoCurrencySymbol.USDC,
    ECryptoCurrencySymbol.USDT,
  ],
  [ECryptoNetworkSymbol.POLYGON]: [
    ECryptoCurrencySymbol.BTC,
    ECryptoCurrencySymbol.BUSD,
    ECryptoCurrencySymbol.DAI,
    ECryptoCurrencySymbol.MATIC,
    ECryptoCurrencySymbol.USDC,
    ECryptoCurrencySymbol.USDT,
    ECryptoCurrencySymbol.KAP,
  ],
  [ECryptoNetworkSymbol.ARBITRUM]: [
    ECryptoCurrencySymbol.BTC,
    ECryptoCurrencySymbol.DAI,
    ECryptoCurrencySymbol.ETH,
    ECryptoCurrencySymbol.USDC,
    ECryptoCurrencySymbol.USDT,
    ECryptoCurrencySymbol.KAP,
  ],
  [ECryptoNetworkSymbol.AVALANCHE]: [
    ECryptoCurrencySymbol.AVAX,
    ECryptoCurrencySymbol.DAI,
    ECryptoCurrencySymbol.USDC,
    ECryptoCurrencySymbol.USDT,
  ],
  [ECryptoNetworkSymbol.SOLANA]: [
    ECryptoCurrencySymbol.USDC,
    ECryptoCurrencySymbol.USDT,
    ECryptoCurrencySymbol.SOL,
    ECryptoCurrencySymbol.HONEY,
  ],
};

const TEST_MODE_ENABLED_TOKENS: TLiveModeEnabledTokens = {
  [ECryptoNetworkSymbol.ETHEREUM]: [ECryptoCurrencySymbol.ETH],
  [ECryptoNetworkSymbol.SOLANA]: [ECryptoCurrencySymbol.SOL],
};

export const WYRE_TO_TOKENS: TLiveModeEnabledTokens = {
  [ECryptoNetworkSymbol.ETHEREUM]: [
    ECryptoCurrencySymbol.ETH,
    ECryptoCurrencySymbol.DAI,
    ECryptoCurrencySymbol.USDC,
    ECryptoCurrencySymbol.USDT,
    ECryptoCurrencySymbol.BTC,
    ECryptoCurrencySymbol.BUSD,
  ],
  [ECryptoNetworkSymbol.POLYGON]: [ECryptoCurrencySymbol.MATIC, ECryptoCurrencySymbol.USDC, ECryptoCurrencySymbol.ETH],
  [ECryptoNetworkSymbol.AVALANCHE]: [ECryptoCurrencySymbol.AVAX],
};

export const WYRE_NETWORK_TOKEN: Record<
  ECryptoNetworkSymbol.ETHEREUM | ECryptoNetworkSymbol.POLYGON | ECryptoNetworkSymbol.AVALANCHE,
  ECryptoCurrencySymbol
> = {
  [ECryptoNetworkSymbol.ETHEREUM]: ECryptoCurrencySymbol.ETH,
  [ECryptoNetworkSymbol.POLYGON]: ECryptoCurrencySymbol.MATIC,
  [ECryptoNetworkSymbol.AVALANCHE]: ECryptoCurrencySymbol.AVAX,
};

export const ENABLED_TOKENS = {
  [EMode.LIVE]: LIVE_MODE_ENABLED_TOKENS,
  [EMode.TEST]: TEST_MODE_ENABLED_TOKENS,
};

// map token => listOfSupportedNetworks
export const getTokenNetworkMapping = (liveMode = EMode.LIVE) => {
  const mapping: Map<ECryptoCurrencySymbol, ECryptoNetworkSymbol[]> = new Map();
  for (const [network, tokens] of Object.entries(ENABLED_TOKENS[liveMode])) {
    if (tokens) {
      for (const token of Object.values(tokens)) {
        mapping.get(token)?.push(network as ECryptoNetworkSymbol) ||
          mapping.set(token, [network as ECryptoNetworkSymbol]);
      }
    }
  }
  return mapping;
};

export const OPTIMISM_DEFAULT_GAS_FEE = '0.0002';

export const AVAILABLE_TOKEN_LIST = new Map<ECryptoCurrencySymbol, ECryptoNetworkSymbol[]>([
  [
    ECryptoCurrencySymbol.ETH,
    [ECryptoNetworkSymbol.ETHEREUM, ECryptoNetworkSymbol.ARBITRUM, ECryptoNetworkSymbol.OPTIMISM],
  ],
  [
    ECryptoCurrencySymbol.BTC,
    [
      ECryptoNetworkSymbol.ETHEREUM,
      ECryptoNetworkSymbol.POLYGON,
      ECryptoNetworkSymbol.ARBITRUM,
      ECryptoNetworkSymbol.OPTIMISM,
    ],
  ],
  [
    ECryptoCurrencySymbol.USDT,
    [
      ECryptoNetworkSymbol.ETHEREUM,
      ECryptoNetworkSymbol.SOLANA,
      ECryptoNetworkSymbol.POLYGON,
      ECryptoNetworkSymbol.AVALANCHE,
      ECryptoNetworkSymbol.ARBITRUM,
      ECryptoNetworkSymbol.OPTIMISM,
      ECryptoNetworkSymbol.BINANCE,
    ],
  ],
  [
    ECryptoCurrencySymbol.USDC,
    [
      ECryptoNetworkSymbol.ETHEREUM,
      ECryptoNetworkSymbol.SOLANA,
      ECryptoNetworkSymbol.POLYGON,
      ECryptoNetworkSymbol.AVALANCHE,
      ECryptoNetworkSymbol.ARBITRUM,
      ECryptoNetworkSymbol.OPTIMISM,
      ECryptoNetworkSymbol.BINANCE,
    ],
  ],
  [ECryptoCurrencySymbol.BNB, [ECryptoNetworkSymbol.BINANCE]],
  [
    ECryptoCurrencySymbol.BUSD,
    [ECryptoNetworkSymbol.ETHEREUM, ECryptoNetworkSymbol.POLYGON, ECryptoNetworkSymbol.BINANCE],
  ],
  [ECryptoCurrencySymbol.SOL, [ECryptoNetworkSymbol.SOLANA]],
  [
    ECryptoCurrencySymbol.DAI,
    [
      ECryptoNetworkSymbol.ETHEREUM,
      ECryptoNetworkSymbol.POLYGON,
      ECryptoNetworkSymbol.AVALANCHE,
      ECryptoNetworkSymbol.ARBITRUM,
      ECryptoNetworkSymbol.OPTIMISM,
      ECryptoNetworkSymbol.BINANCE,
    ],
  ],
  [ECryptoCurrencySymbol.MATIC, [ECryptoNetworkSymbol.POLYGON]],
  [ECryptoCurrencySymbol.AVAX, [ECryptoNetworkSymbol.AVALANCHE]],
  [ECryptoCurrencySymbol.HONEY, [ECryptoNetworkSymbol.SOLANA]],
  [
    ECryptoCurrencySymbol.KAP,
    [ECryptoNetworkSymbol.ETHEREUM, ECryptoNetworkSymbol.POLYGON, ECryptoNetworkSymbol.ARBITRUM],
  ],
]);

export enum ECryptoNetworkNativeCurrencySymbol {
  ETHEREUM = 'ETH',
  OPTIMISM = 'ETH',
  BINANCE = 'BNB',
  POLYGON = 'MATIC',
  ARBITRUM = 'ETH',
  AVALANCHE = 'AVAX',
  SOLANA = 'SOL',
}

export enum ECryptoCurrencyDecimals {
  ETHEREUM_USDC = 6,
  ETHEREUM_USDT = 6,
  ETHEREUM_DAI = 18,
  ETHEREUM_BUSD = 18,
  ETHEREUM_BTC = 8,
  ETHEREUM_ETH = 18,
  ETHEREUM_KAP = 18,
  OPTIMISM_USDC = 6,
  OPTIMISM_USDT = 6,
  OPTIMISM_DAI = 18,
  OPTIMISM_BUSD = -1,
  OPTIMISM_BTC = 8,
  OPTIMISM_ETH = 18,
  BINANCE_USDC = 18,
  BINANCE_USDT = 18,
  BINANCE_DAI = 18,
  BINANCE_BUSD = 18,
  BINANCE_BTC = -1,
  BINANCE_BNB = 18,
  POLYGON_USDC = 6,
  POLYGON_USDT = 6,
  POLYGON_DAI = 18,
  POLYGON_BUSD = 18,
  POLYGON_BTC = 8,
  POLYGON_MATIC = 18,
  POLYGON_KAP = 18,
  ARBITRUM_USDC = 6,
  ARBITRUM_USDT = 6,
  ARBITRUM_DAI = 18,
  ARBITRUM_BUSD = -1,
  ARBITRUM_BTC = 8,
  ARBITRUM_ETH = 18,
  ARBITRUM_KAP = 18,
  AVALANCHE_USDC = 6,
  AVALANCHE_USDT = 6,
  AVALANCHE_DAI = 18,
  AVALANCHE_BUSD = -1,
  AVALANCHE_BTC = -1,
  AVALANCHE_AVAX = 18,
  SOLANA_USDC = 6,
  SOLANA_USDT = 6,
  SOLANA_DAI = -1,
  SOLANA_BUSD = -1,
  SOLANA_BTC = -1,
  SOLANA_SOL = 9,
  SOLANA_HONEY = 9,
}

export enum ETestCryptoCurrencyAddress {
  SOLANA_USDC = 'Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr',
  SOLANA_USDT = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  SOLANA_DAI = '',
  SOLANA_BUSD = '',
  SOLANA_BTC = '',
  SOLANA_SOL = '',
}

export enum ECryptoCurrencyAddress {
  GAS_TOKEN = '',
  ETHEREUM_USDC = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  ETHEREUM_USDT = '0xdac17f958d2ee523a2206206994597c13d831ec7',
  ETHEREUM_DAI = '0x6b175474e89094c44da98b954eedeac495271d0f',
  ETHEREUM_BUSD = '0x4fabb145d64652a948d72533023f6e7a623c7c53',
  ETHEREUM_BTC = '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  ETHEREUM_KAP = '0x9625cE7753ace1fa1865A47aAe2c5C2Ce4418569',
  ETHEREUM_ETH = '',
  OPTIMISM_USDC = '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
  OPTIMISM_USDT = '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
  OPTIMISM_DAI = '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  OPTIMISM_BUSD = '',
  OPTIMISM_BTC = '0x68f180fcce6836688e9084f035309e29bf0a2095',
  OPTIMISM_ETH = '',
  BINANCE_USDC = '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  BINANCE_USDT = '0x55d398326f99059ff775485246999027b3197955',
  BINANCE_DAI = '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  BINANCE_BUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  BINANCE_BTC = '',
  BINANCE_BNB = '',
  POLYGON_USDC = '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
  POLYGON_USDT = '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  POLYGON_DAI = '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  POLYGON_BUSD = '0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7',
  POLYGON_BTC = '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  POLYGON_KAP = '0xc27158bB8E08899d38765752F91d7d778e16AB8e',
  POLYGON_MATIC = '',
  ARBITRUM_USDC = '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
  ARBITRUM_USDT = '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
  ARBITRUM_DAI = '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  ARBITRUM_KAP = '0x965d00aA7ABC62CA10132e641D08593435aC811d',
  ARBITRUM_BUSD = '',
  ARBITRUM_BTC = '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
  ARBITRUM_ETH = '',
  AVALANCHE_USDC = '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
  AVALANCHE_USDT = '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
  AVALANCHE_DAI = '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
  AVALANCHE_BUSD = '',
  AVALANCHE_BTC = '',
  AVALANCHE_AVAX = '',
  SOLANA_USDC = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  SOLANA_USDT = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
  SOLANA_DAI = '',
  SOLANA_BUSD = '',
  SOLANA_BTC = '',
  SOLANA_SOL = '',
  SOLANA_HONEY = '4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy',
}
