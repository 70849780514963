import { QueryCache, QueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { isCustomerModule, isDashboardModule } from '@/utils/apexModuleHelper';
import { getErrorMessage } from '@/utils/commonErrors';

const STALE_TIME = 15 * 60 * 1000; // 15 minutes

export const queryClient = new QueryClient({
  // https://tkdodo.eu/blog/react-query-error-handling
  queryCache: new QueryCache({
    onError: (error) => {
      if (isDashboardModule || isCustomerModule) {
        message.error(`Something went wrong: ${getErrorMessage(error)}`);
      }
    },
  }),
  defaultOptions: {
    mutations: {
      onError: (error) => {
        if (isDashboardModule || isCustomerModule) {
          message.error(`Something went wrong: ${getErrorMessage(error)}`);
        }

        return Promise.reject(error);
      },
    },
    queries: {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
    },
  },
});
