import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { PaymentPage } from '@/apexModules/payment-page/types/paymentPage';
import { EPresentmentCurrency } from '@/constants/payment';

interface IPaymentData {
  data: PaymentPage.IPageData;
  error?: SerializedError;
}

const initialState: IPaymentData = {
  data: {
    merchant: {
      name: 'merchant',
      wallet: {
        evmAddress: '',
        solanaAddress: '',
      },
      merchantFp: '',
    },
    paymentConfig: { enabledPaymentMethods: [], enabledTransferTokens: [], evmContractAddress: '' },
    predictedFee: {
      customerPayProcessingFee: false,
    },
    currency: EPresentmentCurrency.USDC,
    amount: 0,
    status: 'UNPAID',
    pageId: '',
    isNFT: false,
    isRecurring: false,
  },
  error: undefined,
};

export const paymentDataSlice = createSlice({
  name: 'paymentData',
  initialState,
  reducers: {
    updateState: (state, action: PayloadAction<Partial<IPaymentData>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    // Update other information (like product response)
    updatePaymentPageData: (state, action: PayloadAction<Partial<PaymentPage.IPageData>>) => {
      const { payload } = action;
      const newData = { ...state.data, ...payload } as PaymentPage.IPageData;

      return {
        data: newData,
        error: undefined,
      };
    },

    updatePaymentPageError: (state, action: PayloadAction<Partial<SerializedError>>) => {
      const { payload } = action;
      const newError = { ...state.error, ...payload } as SerializedError;

      return {
        data: state.data,
        error: newError,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePaymentPageData, updatePaymentPageError } = paymentDataSlice.actions;

export default paymentDataSlice.reducer;
