/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export const enum EPaymentFlow {
  Exchange = 'exchange',
  Card = 'card',
  Crypto = 'crypto',
  Wallet = 'wallet',
  Fiat = 'fiat',
}

export const enum ECryptoPaymentMethod {
  WALLET = 'Crypto Wallet',
  EXCHANGE = 'Crypto Exchange',
}

// const enum will be compiled into code, can't be dynamic getter
export const enum EPaymentStatus {
  // natee: should we rename PREVIEW to PAYMENT_PREVIEW?
  PREVIEW = 'PREVIEW',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  // Wallet flow
  WALLET_OVERVIEW = 'WALLET_OVERVIEW',
  POLLING = 'POLLING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  EXPIRED = 'EXPIRED',
  // exchange flow
  EXCHANGE = 'EXCHANGE',
  // card flow
  CARD = 'CARD',
  IFRAME_3DS = 'IFRAME_3DS',
  // others
  NOT_FOUND = 'NOT_FOUND',
  CURRENCY = 'CURRENCY',
  PAYING = 'PAYING',
  PROCESSING = 'PROCESSING',
  EMAIL_COLLECTION = 'EMAIL_COLLECTION',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  FIAT = 'FIAT',
}

export const enum EPaymentMethod {
  FIAT = 'FIAT',
  CARD = 'CARD',

  // evm or sol
  TRANSFER_CRYPTO = 'TRANSFER_CRYPTO',

  METAMASK = 'METAMASK',
  COINBASE = 'COINBASE',
  WALLET_CONNECT = 'WALLET_CONNECT',
  PHANTOM = 'PHANTOM',
  SOLFLARE = 'SOLFLARE',
  GLOW = 'GLOW',
  SOLLET = 'SOLLET',
  SOLONG = 'SOLONG',
}

export const enum EPresentmentCurrency {
  // Fiat
  AMD = 'AMD',
  ARS = 'ARS',
  AUD = 'AUD',
  BOB = 'BOB',
  BRL = 'BRL',
  CAD = 'CAD',
  CLP = 'CLP',
  COP = 'COP',
  CRC = 'CRC',
  DOP = 'DOP',
  EUR = 'EUR',
  GBP = 'GBP',
  INR = 'INR', // 0 units, 100 means 100 INR
  JPY = 'JPY',
  KRW = 'KRW',
  KZT = 'KZT',
  LKR = 'LKR',
  MXN = 'MXN',
  MYR = 'MYR',
  NZD = 'NZD',
  PEN = 'PEN',
  PKR = 'PKR',
  PYG = 'PYG',
  SGD = 'SGD',
  USD = 'USD',
  UYU = 'UYU',
  VES = 'VES',
  ZAR = 'ZAR',

  // Crypto
  SOL = 'SOL', // 4 units, 10000 means 1 SOL
  USDC = 'USDC', // 2 units, 100 means 1 USDC
  USDT = 'USDT', // 2 units, 100 means 1 USDT
}

export enum EPresentmentCurrencyUnits {
  // Fiat
  AMD = 2,
  ARS = 2,
  AUD = 2,
  BOB = 2,
  BRL = 2,
  CAD = 2,
  CLP = 0,
  COP = 2,
  CRC = 2,
  DOP = 2,
  EUR = 2,
  GBP = 2,
  INR = 0,
  JPY = 0,
  KRW = 0,
  KZT = 2,
  LKR = 2,
  MXN = 2,
  MYR = 2,
  NZD = 2,
  PEN = 2,
  PKR = 2,
  PYG = 0,
  SGD = 2,
  USD = 2,
  UYU = 2,
  VES = 2,
  ZAR = 2,

  // Crypto
  SOL = 4,
  USDC = 2,
  USDT = 2,
}

export const enum EBreezeSDK {
  // handle widget
  BREEZE_WIDGET_CLOSE_REQUEST = 'BREEZE_WIDGET_CLOSE_REQUEST',
  BREEZE_WIDGET_CLOSE_REQUEST_CONFIRMED = 'BREEZE_WIDGET_CLOSE_REQUEST_CONFIRMED',
  BREEZE_WIDGET_CLOSE_REQUEST_CANCELLED = 'BREEZE_WIDGET_CLOSE_REQUEST_CANCELLED',
  BREEZE_WIDGET_READY = 'BREEZE_WIDGET_READY',
  BREEZE_WIDGET_FAILED = 'BREEZE_WIDGET_FAILED',

  // handle connect feature
  BREEZE_WIDGET_TX_COMPLETED = 'BREEZE_WIDGET_TX_COMPLETED',
  BREEZE_WIDGET_TX_FAILED = 'BREEZE_WIDGET_TX_FAILED',
  BREEZE_WIDGET_3DS_CALLBACK = 'BREEZE_WIDGET_3DS_CALLBACK',

  BREEZE_WIDGET_RESIZE = 'BREEZE_WIDGET_RESIZE',
  BREEZE_WIDGET_LOGIN_SESSION = 'BREEZE_WIDGET_LOGIN_SESSION',
}

export const CUSTOMER_FEE_PERCENTAGE = 0.01;
