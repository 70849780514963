import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { RootSiblingParent } from 'react-native-root-siblings';
import { useQueries } from '@tanstack/react-query';
import type { User } from 'firebase/auth';

import { ECryptoNetworkSymbol } from '@/constants/chains';
import { ENABLED_TOKENS } from '@/constants/tokens';
import { useLivemode } from '@/hooks/useLivemode';
import { delay, isWalletCreated } from '@/utils';
import { isCustomerModule, isDashboardModule } from '@/utils/apexModuleHelper';
import { EConnectRelationships, getLocalRoleInfo, setLocalRoleInfo } from '@/utils/role';

import { EChainType, getKeystore } from '../web3auth/keystore';

import { commonApi } from './api-common';
import { dashboardApi } from './api-dashboard';

export interface ISelectedAccountInfo {
  selectedAccount: string;
  relationships: EConnectRelationships;
  email: string;
  walletAddress?: BeamoNS.TEvmWalletAddress;
  wallet?: BeamoNS.IWalletAddress;
}

export interface IBeamoContext {
  account: BeamoNS.IBeamoAccountInfo | null;
  isLoading: boolean;
  user: User | null;
  selectedAccountInfo?: ISelectedAccountInfo;
  changeSelectedAccount: (account: string, relationships?: EConnectRelationships) => void;
  isRoleMatched: (targetRole: EConnectRelationships) => boolean;
}

export const beamoContext = createContext<IBeamoContext>({
  account: null,
  isLoading: true,
  user: null,
  selectedAccountInfo: undefined,
  changeSelectedAccount: () => {
    return;
  },
  isRoleMatched: () => {
    return false;
  },
});

export function useBeamo(): IBeamoContext {
  return useContext(beamoContext);
}

export function useUserEmail() {
  const { user } = useBeamo();
  return user?.email || '';
}

interface IWeb3AuthState {
  children?: ReactNode;
  user: User | null;
}

export const BeamoProvider = ({ children, user }: IWeb3AuthState) => {
  const { livemode, mode } = useLivemode();
  const roleInfo = getLocalRoleInfo();
  let info;
  if (roleInfo?.selectedAccount) {
    info = {
      selectedAccount: roleInfo?.selectedAccount,
      relationships: roleInfo?.relationships,
    } as ISelectedAccountInfo;
  }
  const [selectedAccountInfo, setSelectedAccountInfo] = useState<ISelectedAccountInfo | undefined>(info);
  const [account, setAccount] = useState<BeamoNS.IBeamoAccountInfo | null>(null);

  const [{ data: accountInfo, isLoading: isAccountLoading }, { data: merchants, isLoading: isMerchantLoading }] =
    useQueries({
      queries: [
        { queryKey: ['accountInfo', user?.uid], queryFn: commonApi.getAccountInfo, enabled: !!user },
        {
          queryKey: ['merchantInfo', user?.uid, livemode],
          queryFn: commonApi.getMerchantInfo,
          enabled: !!user && isDashboardModule,
        },
      ],
    });

  const isLoading = isAccountLoading || isMerchantLoading;

  useEffect(() => {
    const processAddress = (walletAddress: BeamoNS.TEvmWalletAddress, wallet: BeamoNS.IWalletAddress) => {
      Object.keys(ENABLED_TOKENS[mode]).forEach((network: ECryptoNetworkSymbol) => {
        if (network == 'SOLANA') {
          walletAddress.set(network, wallet?.solanaAddress || '');
        } else {
          walletAddress.set(network, wallet?.evmAddress || '');
        }
      });
    };

    const processRelationInfo = (acc: BeamoNS.IBeamoAccountInfo, selectedAccountInfo: ISelectedAccountInfo) => {
      const { selectedAccount, relationships } = selectedAccountInfo;
      const { viewers = [], developers = [] } = acc || {};

      const viewer = viewers.find((item) => {
        return item.account === selectedAccount;
      });
      if ((relationships === EConnectRelationships.VIEWER || !relationships) && viewer) {
        return {
          relationships: EConnectRelationships.VIEWER,
          accountInfo: viewer,
        };
      }

      const developer = developers.find((item) => {
        return item.account === selectedAccount;
      });
      if ((relationships === EConnectRelationships.DEVELOPER || !relationships) && developer) {
        return {
          relationships: EConnectRelationships.DEVELOPER,
          accountInfo: developer,
        };
      }

      return {
        relationships: viewer ? EConnectRelationships.VIEWER : EConnectRelationships.DEVELOPER,
        accountInfo: viewer || developer,
      };
    };
    const changeViewerAction = (acc: BeamoNS.IBeamoAccountInfo) => {
      if (selectedAccountInfo) {
        const info = { ...selectedAccountInfo };
        const { relationships, accountInfo } = processRelationInfo(acc, selectedAccountInfo);
        if (accountInfo) {
          info.relationships = relationships;
          info.email = accountInfo.email;
          const wallet = accountInfo?.wallet;
          if (wallet) {
            const walletAddress: BeamoNS.TEvmWalletAddress = new Map();
            processAddress(walletAddress, wallet);
            info.walletAddress = walletAddress;
            info.wallet = wallet;
          } else {
            info.walletAddress = undefined;
            info.wallet = undefined;
          }
          setSelectedAccountInfo(info);
        }
      }
    };

    async function init() {
      if (!user) {
        return;
      }
      try {
        if (accountInfo && (isDashboardModule || isCustomerModule) && !isWalletCreated(accountInfo.wallet)) {
          const ks = await getKeystore(user);
          if (ks) {
            accountInfo.wallet = {
              solanaAddress: ks.get(EChainType.SOLANA)?.PublicAddress || '',
              evmAddress: ks.get(EChainType.EVM)?.PublicAddress || '',
            };
          }
        }
      } catch (error) {
        console.log(error);
        if (isDashboardModule) {
          await delay(2000);
        }
      }

      const acc: BeamoNS.IBeamoAccountInfo = {
        accountId: accountInfo?.id || '',
        merchantId: '',
        kyc: accountInfo?.kyc,
        walletAddress: new Map(),
        email: accountInfo?.email || '',
        viewers: accountInfo?.viewers,
        developers: accountInfo?.developers,
        editors: accountInfo?.editors,
        operators: accountInfo?.operators,
      };

      if (accountInfo && merchants) {
        const merchantId = merchants.find((m) => m.livemode == livemode)?.id || merchants[0].id;
        if (!merchantId) {
          console.error('no merchant available for liveMode:', livemode);
          // TODO improve error handling?
        }
        acc.merchantId = merchantId;
        console.info(`switch to merchantId: ${merchantId} livemode: ${livemode}`);

        dashboardApi.setMerchantId(merchantId);
      }
      if (accountInfo?.wallet) {
        processAddress(acc.walletAddress, accountInfo.wallet);
      }

      if (isDashboardModule) {
        changeViewerAction(acc);
      }
      setAccount(acc);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo, livemode, merchants, selectedAccountInfo?.selectedAccount, user]);

  const changeSelectedAccount = (selectedAccount = '', relationships?: EConnectRelationships) => {
    const info = selectedAccount
      ? ({
          selectedAccount,
          relationships,
        } as ISelectedAccountInfo)
      : undefined;
    setLocalRoleInfo(info);
    setSelectedAccountInfo(info);
    // TODO: invalidate queries instead of reloading the page
    // setTimeout(() => {
    //   queryClient.invalidateQueries();
    // });
  };

  const isRoleMatched = (targetRole: EConnectRelationships) => {
    return selectedAccountInfo?.relationships === targetRole;
  };

  const contextProvider = {
    account,
    isLoading,
    user,
    selectedAccountInfo,
    changeSelectedAccount,
    isRoleMatched,
  };

  return (
    <beamoContext.Provider value={contextProvider}>
      <RootSiblingParent>{children}</RootSiblingParent>
    </beamoContext.Provider>
  );
};
