import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { ECryptoNetworkSymbol } from '@/constants/chains';
import { ECashoutSwapStatus } from '@/constants/enum';
import { ECryptoCurrencySymbol } from '@/constants/tokens';
import { ITokenSwapRoute } from '@/services/beamo/api-common';

interface ICashoutSwapReviewData {
  sourceToken: ECryptoCurrencySymbol;
  destToken: ECryptoCurrencySymbol;
  total: number;
  network: ECryptoNetworkSymbol;
  gasFee?: number;
  gasToken?: ECryptoCurrencySymbol;
  destAmount?: string;
}
export interface ICashoutSwapState {
  show: boolean;
  status: ECashoutSwapStatus;
  sourceToken: ECryptoCurrencySymbol;
  destToken: ECryptoCurrencySymbol;
  statusHistory: ECashoutSwapStatus[];
  dialogTitle?: string;
  reviewData: ICashoutSwapReviewData;
  route?: ITokenSwapRoute;
  transactionUrl?: string;
}
const initalStatus = ECashoutSwapStatus.SELLING;

export const initialState: ICashoutSwapState = {
  show: false,
  status: initalStatus,
  sourceToken: ECryptoCurrencySymbol.HONEY,
  destToken: ECryptoCurrencySymbol.USDC,
  statusHistory: [],
  dialogTitle: '',
  reviewData: {
    sourceToken: ECryptoCurrencySymbol.HONEY,
    destToken: ECryptoCurrencySymbol.USDC,
    total: 0,
    network: ECryptoNetworkSymbol.SOLANA,
  },
  transactionUrl: '',
};

export const cashoutSwapSlice = createSlice({
  name: 'cashoutSwap',
  initialState,
  reducers: {
    // Goes back to the topmost status in history
    previousState: (state, action: PayloadAction<Partial<ICashoutSwapState>>) => {
      const newHistory = [...state.statusHistory];
      const previousStatus = newHistory.pop() || initalStatus;
      return {
        ...state,
        ...action.payload,
        status: previousStatus,
        statusHistory: newHistory,
      };
    },
    // Skips the status in the history
    skipState: (state, action: PayloadAction<Partial<ICashoutSwapState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    // Stores the status in the history
    nextState: (state, action: PayloadAction<Partial<ICashoutSwapState>>) => {
      return {
        ...state,
        ...action.payload,
        statusHistory: [...state.statusHistory, state.status],
      };
    },
    // Update other information (like product response)
    updateState: (state, action: PayloadAction<Partial<ICashoutSwapState>>) => {
      // Go to the next state

      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => initialState,
    resetCashoutSwapState: (state) => {
      return {
        ...state,
        ...cloneDeep(initialState),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { previousState, skipState, nextState, updateState, reset, resetCashoutSwapState } =
  cashoutSwapSlice.actions;

export default cashoutSwapSlice.reducer;
