import { ComponentType, ReactNode, useEffect, useMemo, useState } from 'react';
import RootSiblingsManager from 'react-native-root-siblings';
import ChildrenWrapper from 'react-native-root-siblings/lib/ChildrenWrapper';
import wrapRootComponent, { RootSiblingManager } from 'react-native-root-siblings/lib/wrapRootComponent';
import { throttle } from 'lodash';

type TOverlayContentRender = (closeOverlay: () => void) => ReactNode;

// used to check if the same overlay was created
export const overlayInstances: Map<string, boolean> = new Map();

export const showOverlay = throttle(
  (renderOverlay: TOverlayContentRender, overlayName: string) => {
    let rootNode: RootSiblingsManager | null;
    const closeOverlay = () => {
      rootNode?.destroy();
      rootNode = null;
      overlayInstances.delete(overlayName);
    };

    overlayInstances.set(overlayName, true);
    rootNode = new RootSiblingsManager(renderOverlay(closeOverlay));

    return closeOverlay;
  },
  500,
  {
    leading: true,
    trailing: false,
  }
);

const portalManagers: Map<string, RootSiblingManager> = new Map();
let portalUuid = 0;

function createPortalId(id: number): string {
  return `portal:${id}`;
}

export function isPortalExisted(name: string): boolean {
  return portalManagers.has(name);
}

export interface IPortalManager {
  update: (updater: ReactNode, updateCallback?: (() => void) | undefined) => void;
  destroy: (destroyCallback?: () => void) => void;
}

export function enterPortal(target: string, guest: ReactNode, callback?: () => void): IPortalManager {
  const manager = portalManagers.get(target);
  const id = createPortalId(++portalUuid);

  if (manager) {
    manager.update(id, guest, callback);
  } else {
    // throw new Error(`react-native-root-portal: Can not find target PortalExit named:'${target}'.`);
  }

  return {
    update: (updater: ReactNode, updateCallback?: () => void) => {
      manager?.update(id, updater, updateCallback);
    },
    destroy: (destroyCallback?: () => void) => {
      manager?.destroy(id, destroyCallback);
    },
  };
}

export function PortalEntry(props: { children: ReactNode; target?: string }) {
  const { children, target } = props;
  const manager = target ? portalManagers.get(target) : null;
  const [id] = useState<number>(() => ++portalUuid);

  useEffect(() => {
    return () => manager?.destroy(createPortalId(id));
  }, [id, manager]);

  if (manager) {
    manager.update(createPortalId(id), <>{children}</>);
  } else if (target) {
    // console.error(`react-native-root-portal: Can not find target PortalExit named:'${target}'.`);
  } else {
    return <>{children}</>;
  }

  return null;
}

export function PortalExit(props: {
  name: string;
  renderSibling?: (sibling: ReactNode) => ReactNode;
  children?: ReactNode;
}) {
  const { name, renderSibling, children } = props;

  const sibling = useMemo<{
    Root: ComponentType<{ children?: ReactNode }>;
    manager: RootSiblingManager;
  }>(() => {
    const { Root, manager } = wrapRootComponent(ChildrenWrapper, renderSibling);

    // if (isPortalExisted(name)) {
    //   console.warn(`react-native-root-portal: Another PortalExit named:'${name}' is already existed.`);
    // }

    portalManagers.set(name, manager);
    return {
      Root,
      manager,
    };
  }, [name, renderSibling]);

  useEffect(() => {
    if (!portalManagers.has(name)) {
      portalManagers.set(name, sibling.manager);
    }

    return () => {
      portalManagers.delete(name);
    };
  }, [name, sibling.manager]);

  const { Root } = sibling;
  return (
    <>
      {children}
      <Root />
    </>
  );
}
