import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { EOfframpRequestStatus, EOfframpStepStatus } from '@/beamo-offramp/utils/constant';
import { EOfframp2Step } from '@/components/steps-container/offramp2Steps';
import { OfframpNS } from '@/types/offramp';

export const initialStepStatus = EOfframpStepStatus.OVERVIEW;

export const initialState: OfframpNS.IRedux = {
  stepStatus: initialStepStatus,
  stepHistory: [],
  dialogTitle: '',

  email: '',
  verifyCode: '',
  userInfo: {
    firstName: '',
    middleName: '',
    lastName: '',
    phone: '',
  },
  offrampId: '',
  userToken: '',
  autoLoggedIn: false,
  bankAccounts: [],
  merchant: {
    name: '',
  },

  depositAddress: '',
  depositTotalAmount: 0,
  amount: 0,
  token: 'USDC',
  network: 'polygon',
  status: EOfframpRequestStatus.CREATED,

  livemode: process.env.NODE_ENV === 'production',

  activeStep: EOfframp2Step.OVERVIEW,
};

export const offrampSlice = createSlice({
  name: 'offramp',
  initialState,
  reducers: {
    // Goes back to the topmost status in history
    previousState: (state, action: PayloadAction<OfframpNS.TReduxPartial>) => {
      const newHistory = [...state.stepHistory];
      const previousStatus = newHistory.pop() || initialStepStatus;
      return {
        ...state,
        ...action.payload,
        stepStatus: previousStatus,
        stepHistory: newHistory,
      };
    },
    // Skips the status in the history
    skipState: (state, action: PayloadAction<OfframpNS.TReduxPartial>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    // Stores the status in the history
    nextState: (state, action: PayloadAction<OfframpNS.TReduxPartial>) => {
      return {
        ...state,
        ...action.payload,
        stepHistory: [...state.stepHistory, state.stepStatus],
      };
    },
    // Update other information (like product response)
    updateState: (state, action: PayloadAction<OfframpNS.TReduxPartial>) => {
      // Go to the next state

      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => initialState,
    resetBeamoOfframpState: (state) => {
      return {
        ...state,
        ...cloneDeep(initialState),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { previousState, skipState, nextState, updateState, reset, resetBeamoOfframpState } = offrampSlice.actions;

export default offrampSlice.reducer;
