import { ReactNode } from 'react';

import { cx } from '@/utils';

import BeamoIcon from '../beamo-icon';

import { TBreezeStep, useSteps } from './useSteps';

function DashedBorder({
  color = 'var(--brStepLineColor)',
  style = {},
}: {
  color?: string;
  style?: React.CSSProperties;
}) {
  // vertical border
  return (
    <div
      style={{
        width: '2px',
        // 18px equals to the circle's height
        height: '100%',
        backgroundImage: `linear-gradient(to bottom, ${color} 0%, ${color} 50%, transparent 50%)`,
        backgroundSize: '2px 8px',
        backgroundRepeat: 'repeat-y',
        margin: '2px 0',
        ...style,
      }}
    />
  );
}

export interface IStepsSectionProps {
  step: TBreezeStep;
  activeStep: TBreezeStep;
  steps: TBreezeStep[];
  children?: ReactNode;
  title?: string;
  completedClassName?: string;
  disabledClassName?: string;
  activeClassName?: string;
  iconWrapperClassName?: string;
  iconNotActiveClassName?: string;
  icon?: ReactNode;
  /**
   * vertical offset
   */
  iconOffset?: number;
  /**
   * Whether to show the dashed line between steps.
   */
  showLine?: boolean;
}

function StepsSection({
  step,
  activeStep,
  steps,
  children,
  title,
  completedClassName = '',
  disabledClassName = '',
  activeClassName = '',
  iconWrapperClassName,
  iconNotActiveClassName,
  icon,
  iconOffset = 0,
  showLine = true,
}: IStepsSectionProps) {
  const { isBeforeCurrent, isAfterCurrent, isLast, isHidden, isCurrent } = useSteps({ step, activeStep, steps });

  const iconOffsetStyle = {
    transform: `translateY(${iconOffset}px)`,
  };

  const containerClassName = isHidden
    ? 'hidden'
    : isAfterCurrent
      ? disabledClassName
      : isBeforeCurrent
        ? completedClassName
        : activeClassName;

  iconWrapperClassName =
    iconWrapperClassName ??
    (isBeforeCurrent
      ? 'bg-brStepLineCompletedColor'
      : isCurrent || (isLast && !isAfterCurrent)
        ? `bg-brBodyBgColor
        border-2 border-solid border-brStepLineCompletedColor 
        `
        : `border border-solid border-2 border-brStepLineColor bg-brBodyBgColor ${iconNotActiveClassName}`);

  const leftTitleComponent = !!title && (
    // use absolute position to allow title longer than the container
    <div className='-mt-1 text-base text-brStepLabelColor absolute right-0 w-[300px]' style={iconOffsetStyle}>
      {title}
    </div>
  );
  const rightTitleComponent = !!title && <div className='-mt-1 mb-1 text-base text-brStepLabelColor'>{title}</div>;
  const stepIconComponent = (
    <div
      className={cx(
        `flex absolute z-20 top-0 h-[18px] w-[18px] items-center justify-center rounded-full 
            text-base font-bold shadow-[0_0_0_2px_var(--brBodyBgColor)]`,
        iconWrapperClassName
      )}
      style={{ top: iconOffset + 'px' }}
    >
      {icon ??
        (isBeforeCurrent && (
          <BeamoIcon
            icon='CheckBlue'
            stroke='var(--brStepIconColor, white)'
            fill='var(--brStepIconColor, white)'
            size={14}
          />
        ))}
    </div>
  );

  // for this case, should hide bottom part of the line
  const hideLine = isLast || !showLine;

  /**
   * before current: [solid, solid] both were solid line
   * current step: [solid, dash] above part should be solid line, bottom part should be dashed line
   * after current step: [dash, dash] both were dashed line
   * last step: [solid, null] should hide bottom part of the line
   */
  const dashedLineComponent = (
    <DashedBorder color={isCurrent ? 'var(--brStepLineCompletedColor)' : 'var(--brStepLineColor)'} />
  );
  const solidLineComponent = <div className='h-full flex-1 border-l-2 border-brStepLineCompletedColor border-solid ' />;

  const topLineComponent = (
    <div className='absolute top-0 left-1/2 -ml-[1px]' style={{ height: `${iconOffset}px` }}>
      {isAfterCurrent ? dashedLineComponent : solidLineComponent}
    </div>
  );
  const bottomLineComponent = (
    <div className='absolute bottom-0 left-1/2 -ml-[1px]' style={{ top: `${iconOffset}px` }}>
      {isBeforeCurrent ? solidLineComponent : dashedLineComponent}
    </div>
  );

  return (
    <div className={cx('flex flex-row', containerClassName)}>
      <div className='hidden md:block w-32 text-right relative'>{isAfterCurrent ? null : leftTitleComponent}</div>
      <div className='mx-3 flex flex-col items-center w-[18px] relative'>
        {stepIconComponent}
        {/* line */}
        <div className='flex-1 absolute z-10 top-0 bottom-0'>
          {topLineComponent}
          {!hideLine && bottomLineComponent}
        </div>
      </div>
      {/* title */}
      <div className='flex-1 pb-8'>
        {isAfterCurrent && rightTitleComponent} <div className={isAfterCurrent ? 'hidden' : ''}>{children}</div>
      </div>
    </div>
  );
}

export default StepsSection;
