import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { EConnectStatus } from '@/beamo-connect/utils/constant';
import { IConnectData } from '@/services/beamo/api-connect';

export interface IConnectBeamoState {
  emailUserTyped?: string;
  status: EConnectStatus;
  statusHistory: EConnectStatus[];
  dialogTitle?: string;

  connectData: IConnectData;
  requestId: string;
  solanaSignature?: string;
  wallet?: BeamoNS.IWalletAddress;
}

const initialStatus = EConnectStatus.EMAIL_COLLECTION;
export const initialState: IConnectBeamoState = {
  emailUserTyped: '',
  status: initialStatus,
  statusHistory: [],
  dialogTitle: '',

  connectData: { senderName: '', receiverEmail: '', status: 'PENDING' },
  requestId: '',
};

export const connectSlice = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    // Goes back to the topmost status in history
    previousState: (state, action: PayloadAction<Partial<IConnectBeamoState>>) => {
      const newHistory = [...state.statusHistory];
      const previousStatus = newHistory.pop() || initialStatus;
      return {
        ...state,
        ...action.payload,
        status: previousStatus,
        statusHistory: newHistory,
      };
    },
    // Skips the status in the history
    skipState: (state, action: PayloadAction<Partial<IConnectBeamoState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    // Stores the status in the history
    nextState: (state, action: PayloadAction<Partial<IConnectBeamoState>>) => {
      return {
        ...state,
        ...action.payload,
        statusHistory:
          action.payload.status !== state.status && !!action.payload.status
            ? // only add history if status is defined and different from previous
              [...state.statusHistory, state.status]
            : state.statusHistory,
      };
    },
    // Update other information (like product response)
    updateState: (state, action: PayloadAction<Partial<IConnectBeamoState>>) => {
      // Go to the next state

      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => initialState,
    resetConnectBeamoState: (state) => {
      return {
        ...state,
        ...cloneDeep(initialState),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { previousState, skipState, nextState, updateState, reset, resetConnectBeamoState } = connectSlice.actions;

export default connectSlice.reducer;
