import { ComponentType, CSSProperties, lazy, Suspense, SVGProps, useMemo } from 'react';

import { TIconNames } from '../icons';
import { TIconCoinNames } from '../icons/coin';
import { TIconMenuNames } from '../icons/menu';
import { TIconNetworkNames } from '../icons/network';

const getBoxValue = (size: number | string, field?: number | string) => {
  if (field) {
    return typeof field === 'string' ? field : field + 'px';
  } else {
    return typeof size === 'string' ? size : size + 'px';
  }
};

type TIconWithPrefix =
  | {
      icon?: TIconNames;
      prefix?: '';
    }
  | {
      icon: TIconCoinNames;
      prefix: 'coin';
    }
  | {
      icon: TIconMenuNames;
      prefix: 'menu';
    }
  | {
      icon: TIconNetworkNames;
      prefix: 'network';
    };

export type TBeamoIconProps = {
  src?: string;
  alt?: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  size?: number | string;
  style?: CSSProperties;
  fill?: string;
  stroke?: string;
} & TIconWithPrefix;
function BeamoIcon({
  icon,
  src = '',
  alt,
  prefix,
  className = '',
  size = 20,
  width,
  height,
  style,
  fill,
  stroke,
}: TBeamoIconProps) {
  const finalWidth = getBoxValue(size, width);
  const finalHeight = getBoxValue(size, height);
  const iconStyles: CSSProperties = {
    width: finalWidth,
    height: finalHeight,
    lineHeight: finalHeight,
    ...style,
  };

  const renderIcon = useMemo(() => {
    if (icon) {
      let iconName = icon as string;
      if (prefix === 'coin' || prefix === 'network') {
        // capitalize first letter
        iconName = icon.charAt(0).toUpperCase() + icon.slice(1).toLowerCase();
      }
      const Element = lazy<ComponentType<SVGProps<any>>>(
        () => import(`../icons/${prefix ? prefix + '/' : ''}${iconName}`)
      );
      return (
        <Suspense>
          <Element
            width={finalWidth.indexOf('%') ? '100%' : finalWidth}
            height={finalWidth.indexOf('%') ? '100%' : finalHeight}
            {...(fill && { fill })}
            {...(stroke && { stroke })}
          />
        </Suspense>
      );
    }
    return null;
  }, [fill, finalHeight, finalWidth, icon, prefix, stroke]);

  return (
    <div className={`select-none ${className}`} style={iconStyles}>
      {src ? <img alt={alt} src={src} /> : icon ? renderIcon : null}
    </div>
  );
}
BeamoIcon.displayName = 'BeamoIcon';

export default BeamoIcon;
