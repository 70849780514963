import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PaymentPage } from '@/apexModules/payment-page/types/paymentPage';
import { EPaymentMethod } from '@/constants/payment';
import { TRootState } from '@/store';
import { isProdDeployEnv } from '@/utils';

export const parsePaymentPageData = (paymentPageData: PaymentPage.IPageData) => {
  const { enabledPaymentMethods, enabledEvmTokens = [], enabledSolanaTokens = [] } = paymentPageData.paymentConfig;

  let useEvmWalletPayment = enabledEvmTokens.length > 0 && enabledPaymentMethods.includes(EPaymentMethod.METAMASK);
  let useSolWalletPayment = enabledSolanaTokens.length > 0 && enabledPaymentMethods.includes(EPaymentMethod.PHANTOM);
  let useExchangePayment = enabledPaymentMethods.includes(EPaymentMethod.TRANSFER_CRYPTO);
  let useCardPayment = enabledPaymentMethods.includes(EPaymentMethod.CARD);

  // to test pay tabs ui behavior
  // like ?card=0&evm=1&exchange=0
  if (!isProdDeployEnv) {
    const searchParams = new URLSearchParams(location.search);
    const evmParams = searchParams.get('evm');
    const solParams = searchParams.get('sol');
    const cardParams = searchParams.get('card');
    const exchangeParams = searchParams.get('exchange');
    if (evmParams) {
      useEvmWalletPayment = evmParams === '1';
    }
    if (solParams) {
      useSolWalletPayment = solParams === '1';
    }
    if (cardParams) {
      useCardPayment = cardParams === '1';
    }
    if (exchangeParams) {
      useExchangePayment = exchangeParams === '1';
    }
  }
  const useWalletPayment = useEvmWalletPayment || useSolWalletPayment;

  return {
    enabledPaymentMethods,
    paymentPageData,
    useEvmWalletPayment,
    useSolWalletPayment,
    useExchangePayment,
    useWalletPayment,
    useCryptoPayment: useWalletPayment || useExchangePayment,
    useCardPayment,
  };
};

export const usePaymentPageData = () => {
  const paymentPageData = useSelector((state: TRootState) => state.paymentPageData.data);
  return parsePaymentPageData(paymentPageData);
};

export const usePaymentParams = () => {
  const { pathname } = useLocation();
  const [, page, pcs, paymentTab] = pathname.split('/');
  return { page, pcs, paymentTab } as { page: string; pcs: string; paymentTab: 'card' | 'crypto' | 'status' };
};
