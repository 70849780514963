import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ECryptoPaymentMethod } from '@/constants/payment';
import { usePaymentParams } from '@/hooks/usePaymentPageData';
import { TRootState, useAppDispatch } from '@/store';
import { updateState } from '@/store/paymentSlice';

import StepsSection, { IStepsSectionProps } from './section';
import { TBreezeStep, useSteps } from './useSteps';

export const enum EPay2Step {
  PAY_WITH_TABS = 'PAY_WITH_TABS',
  CARD_DETAILS = 'CARD_DETAILS',
  CARD_SUBMIT = 'CARD_SUBMIT',
  CRYPTO_METHODS = 'CRYPTO_METHODS',
  WALLET_SELECTION = 'WALLET_SELECTION',
  WALLET_PAY = 'WALLET_PAY',
  EXCHANGE_SELECT_CRYPTO = 'EXCHANGE_SELECT_CRYPTO',
  EXCHANGE_PAY = 'EXCHANGE_PAY',
}

export const cardFlowSteps = [EPay2Step.PAY_WITH_TABS, EPay2Step.CARD_DETAILS, EPay2Step.CARD_SUBMIT];
export const walletFlowSteps: TBreezeStep[] = [
  EPay2Step.PAY_WITH_TABS,
  EPay2Step.CRYPTO_METHODS,
  EPay2Step.WALLET_SELECTION,
  EPay2Step.WALLET_PAY,
];
export const exchangeFlowSteps: TBreezeStep[] = [
  EPay2Step.PAY_WITH_TABS,
  EPay2Step.CRYPTO_METHODS,
  EPay2Step.EXCHANGE_SELECT_CRYPTO,
  EPay2Step.EXCHANGE_PAY,
];

export const usePay2Steps = (step?: TBreezeStep) => {
  const activeCryptoStep = useSelector((state: TRootState) => state.payment.activeCryptoStep);
  const activeCardStep = useSelector((state: TRootState) => state.payment.activeCardStep);
  const { paymentTab } = usePaymentParams();
  const dispatch = useAppDispatch();
  const activeStep: TBreezeStep = paymentTab === 'card' ? activeCardStep : activeCryptoStep;
  const steps =
    paymentTab === 'card' ? cardFlowSteps : walletFlowSteps.includes(activeStep) ? walletFlowSteps : exchangeFlowSteps;
  const cryptoPaymentMethod = steps === walletFlowSteps ? ECryptoPaymentMethod.WALLET : ECryptoPaymentMethod.EXCHANGE;

  const { activeIndex, ...conditions } = useSteps({
    step,
    activeStep,
    steps,
  });

  const nextStep = useCallback(
    (newStep?: TBreezeStep) => {
      let next = newStep;
      if (!newStep && activeIndex < steps.length - 1) {
        next = steps[activeIndex + 1];
      }
      if (next) {
        if (paymentTab === 'card') {
          dispatch(updateState({ activeCardStep: next }));
        } else {
          dispatch(updateState({ activeCryptoStep: next }));
        }
      }
    },
    [activeIndex, steps, paymentTab, dispatch]
  );

  return {
    steps,
    nextStep,
    activeStep,
    cryptoPaymentMethod,
    ...conditions,
  };
};

export const Pay2StepsSection = ({ step, ...props }: Omit<IStepsSectionProps, 'activeStep' | 'steps'>) => {
  const { steps, activeStep } = usePay2Steps(step);

  return <StepsSection activeStep={activeStep} steps={steps} step={step} {...props} />;
};
