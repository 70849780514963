export const enum EBeamoConnect {
  // handle widget
  BEAMO_WIDGET_CLOSE_REQUEST = 'BEAMO_WIDGET_CLOSE_REQUEST',
  BEAMO_WIDGET_CLOSE_REQUEST_CONFIRMED = 'BEAMO_WIDGET_CLOSE_REQUEST_CONFIRMED',
  BEAMO_WIDGET_CLOSE_REQUEST_CANCELLED = 'BEAMO_WIDGET_CLOSE_REQUEST_CANCELLED',
  BEAMO_WIDGET_READY = 'BEAMO_WIDGET_READY',
  BEAMO_WIDGET_FAILED = 'BEAMO_WIDGET_FAILED',

  // handle connect feature
  BEAMO_WIDGET_TX_COMPLETED = 'BEAMO_WIDGET_TX_COMPLETED',
  BEAMO_WIDGET_TX_FAILED = 'BEAMO_WIDGET_TX_FAILED',
}

export const enum EConnectStatus {
  EMAIL_COLLECTION,
  AUTHENTICATE,
  APPROVE,
  PROCESSING,
  SUCCESS,
  CREATE_ACCOUNT,
  CREATE_WALLET,
  SIGN_MESSAGE,
  CANCELED,
}
