import { CSSProperties } from 'react';

import styles from './index.module.scss';

function Spinner(props: { size?: number; strokeWidth?: number; className?: string }) {
  const { size = 32, strokeWidth = 4, className = '' } = props;
  const style: CSSProperties = {};
  style['--spinner-size'] = size + 'px';
  style['--spinner-stroke-width'] = strokeWidth + 'px';

  return (
    <div className={`${styles['lds-ring']} ${className}`} style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner;
