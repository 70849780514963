import * as Sentry from '@sentry/browser';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import * as rax from 'retry-axios';

import { grecaptchaExecute } from '@/components/recaptcha/recaptchaFallback';
import { showV2ReCaptchaElement } from '@/components/recaptcha/recaptchaModal';
import { EHttpRequestHeader } from '@/constants/enum';
import { getLivemode } from '@/hooks/useLivemode';
import { accountReadyLock, userReadyLock } from '@/services/firebaseAuth';
import { getUrlParam, isProdDeployEnv } from '@/utils';
import { isDashboardModule } from '@/utils/apexModuleHelper';
import { classifyApiError } from '@/utils/commonErrors';
import { getLocalRoleInfo } from '@/utils/role';

export const API_VERSION = 'v1';

export const API_BASE_URL = `${process.env.API_HOST}/${API_VERSION}`;

export interface IPagingQuery {
  limit?: number;
  offset?: number;
}

export interface IBaseTimeStamp {
  createdAt: number;
  updatedAt?: number;
}

export interface IArrayData<T> {
  count: number;
  results: T[];
}
export interface IApiResponseV1<T> {
  status: 'SUCCEEDED' | 'FAILED';
  errorCode?: string;
  errorMessage?: string;
  data: T;
  hasMore?: boolean;
}

export interface IApiResponseError {
  status: 'FAILED';
  errorCode?: string;
  errorMessage?: string;
}

export enum EApiErrorCode {
  PAYMENT_ALREADY_IN_PROGRESS = 'PAYMENT_ALREADY_IN_PROGRESS',
}

let lastReqUrl = '';
let lastReqData: any = null;
let lastReqHeaders: any = null;

export const createBaseAxios = ({
  needAuthToken,
  useLivemode,
  needReCaptcha,
}: { needAuthToken?: boolean; useLivemode?: boolean; needReCaptcha?: boolean } | undefined = {}) => {
  const options: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    timeout: 20 * 1000,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };

  const instance = axios.create(options);

  instance.defaults.raxConfig = {
    retry: needReCaptcha ? 20 : 3,
    backoffType: 'static',
    retryDelay: needReCaptcha ? 2000 : 100,
    onRetryAttempt: (err) => {
      // TODO: To be removed when https://github.com/axios/axios/issues/5089 gets closed.
      err.config!.headers = JSON.parse(JSON.stringify(err.config?.headers || {}));
    },
    // Override the decision making process on if you should retry
    shouldRetry: (err) => {
      const { currentRetryAttempt = 0, retry = 0 } = rax.getConfig(err) || {};
      if (currentRetryAttempt >= retry) return false; // ensure max retries is always respected

      if (err.message === 'Network Error') return true;

      if (
        needReCaptcha &&
        (err.response?.data as { errorCode: string; errorMessage: string })?.errorCode === 'RECAPTCHA_CHALLENGE_FAILED'
      ) {
        showV2ReCaptchaElement(err.response);
        return true;
      }

      // Handle the request based on your other config options, e.g. `statusCodesToRetry`
      return rax.shouldRetryRequest(err);
    },
  };
  rax.attach(instance);

  instance.interceptors.request.use(async (config) => {
    if (needAuthToken && !config.headers['Authorization']) {
      if (!(config.url === '/accounts' && config.method === 'get')) {
        await accountReadyLock.promise;
      }
      const currentUser = await userReadyLock.promise;
      const token = await currentUser?.getIdToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    if (useLivemode) {
      config.params = {
        livemode: getLivemode(),
        ...config.params,
      };
    }

    if (needReCaptcha) {
      const { token: recaptchaToken, version: recaptchaVersion } = await grecaptchaExecute(config.url || 'api');
      config.headers['g-recaptcha-response'] = recaptchaToken;
      config.headers['g-recaptcha-version'] = recaptchaVersion;
      if (!isProdDeployEnv && getUrlParam('recaptcha') === '0') {
        config.headers['g-recaptcha-version'] = 0;
      }
    }

    // viewer
    const { selectedAccount } = getLocalRoleInfo() || {};
    if (selectedAccount && config.url !== '/accounts' && isDashboardModule) {
      config.headers![EHttpRequestHeader.ACCOUNT] = selectedAccount;
    }
    lastReqUrl = config.url || '';
    lastReqData = config.data;
    lastReqHeaders = config.headers;
    return config;
  });

  instance.interceptors.response.use(
    (res) => {
      let ret = undefined;

      if (res.request instanceof XMLHttpRequest) {
        ret = res.data?.data;
      } else {
        // retry-axios retry's returning only contain response.data.data
        ret = res;
      }

      if (!ret) {
        throw new AxiosError('No data in response', undefined, res.config, res.request, res);
      }
      return ret;
    },
    (error: AxiosError<any>) => {
      const response: any = error.response || {};
      const reqUrl: string = response.config?.url || '';
      const resData = response.data;

      const captureErrorDetails = classifyApiError(error);

      if (captureErrorDetails.needToCapture) {
        let extraParams = {};
        if (reqUrl) {
          extraParams = {
            reqHeaders: response.config?.headers,
            reqData: response.config?.data,
            reqUrl,
            statusText: response.statusText,
            resData,
          };
        } else {
          extraParams = {
            lastReqUrl,
            lastReqData,
            lastReqHeaders,
          };
        }

        Sentry.withScope((scope) => {
          // Add error category
          if (captureErrorDetails.fingerprint) scope.setFingerprint(captureErrorDetails.fingerprint);
          scope.setTag('priority', captureErrorDetails.priority);
          scope.setExtra('extra', extraParams);

          scope.addBreadcrumb({
            type: 'http',
            category: 'axios',
            level: 'error',
            data: captureErrorDetails.errorInfo,
          });

          // Set extra context data
          if (captureErrorDetails.requestDetails)
            scope.setContext('Request Details', captureErrorDetails.requestDetails);

          if (captureErrorDetails.responseDetails)
            scope.setContext('Response Details', captureErrorDetails.responseDetails);

          // Capture the exception
          Sentry.captureException(captureErrorDetails.error);
        });
      }

      return Promise.reject(error as AxiosError<IApiResponseError>);
    }
  );

  return instance;
};

export const authedInstance = createBaseAxios({ needAuthToken: true });
export const authedInstanceWithLivemode = createBaseAxios({ needAuthToken: true, useLivemode: true });
export const unauthedInstance = createBaseAxios();
export const reCaptchaInstance = createBaseAxios({ needReCaptcha: true });
