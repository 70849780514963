import { Skeleton } from 'antd';

export const PaymentV2Skeleton = () => {
  return (
    <div
      style={{ fontFamily: 'var(--brFontFamily)' }}
      className='flex flex-col mx-auto max-w-[548px] p-4 pt-10 md:pr-0 h-full font-medium text-brTextColor w-full'
    >
      <Skeleton.Input active className='m-0 md:ml-16' style={{ height: '250px', width: '300px', flex: '1 1 90vw' }} />
      <Skeleton.Input
        active
        className='m-0 md:ml-16'
        style={{ height: '40px', width: '100%', flex: '1 1 90vw', marginTop: '1rem' }}
      />
      <Skeleton.Input
        active
        className='m-0 md:ml-16'
        style={{ height: '200px', width: '100%', flex: '1 1 90vw', marginTop: '1rem' }}
      />
      <Skeleton.Input
        active
        className='m-0 md:ml-16'
        style={{ height: '40px', width: '100px', flex: '1 1 90vw', marginTop: '1rem' }}
      />
    </div>
  );
};
