export enum EConnectRelationships {
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
  DEVELOPER = 'DEVELOPER',
  OPERATOR = 'OPERATOR',
}

export interface IRoleInfo {
  selectedAccount: string;
  relationships?: EConnectRelationships;
}
export const SELECTED_ACCOUNT_KEY = 'beamo:selectedaccount';

let roleInfo: IRoleInfo | undefined;
export const getLocalRoleInfo = () => {
  if (!roleInfo) {
    const localStr = localStorage.getItem(SELECTED_ACCOUNT_KEY);
    try {
      roleInfo = localStr ? JSON.parse(localStr) : '';
    } catch (e) {
      roleInfo = {
        selectedAccount: localStr,
      } as IRoleInfo;
    }
  }
  return roleInfo;
};

export const setLocalRoleInfo = (newRole?: IRoleInfo) => {
  roleInfo = newRole;
  localStorage.setItem(SELECTED_ACCOUNT_KEY, JSON.stringify(roleInfo || ''));
};

export const clearLocalRoleInfo = () => {
  setLocalRoleInfo();
};
