export const enum ESendCryptoStatus {
  CHOOSE_RECIPIENT = 0,
  CHOOSE_CURRENCY,
  SEND_CRYPTO,
  REVIEW,
  VERIFY_EMAIL,
  PROCESSING,
  SUCCESS,
}

export const SOLANA_ACCOUNT_MINIMUM_RENT = 0.003; // actually 0.00100224 SOL will be enough but we reserve a little more

export const SEND_CRYPTO_MINIMUM_AMOUNT = 0.000001;

export const GASLESS_SPL_TOKENS = ['USDT', 'USDC', 'HONEY'];
