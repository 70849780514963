import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { ESendCryptoStatus } from '@/constants/sendCrypto';
import { ECryptoCurrencySymbol } from '@/constants/tokens';
import { ISendCryptoState } from '@/types/sendCrypto';

export const initialState: ISendCryptoState = {
  status: ESendCryptoStatus.CHOOSE_RECIPIENT,
  statusHistory: [],
  dialogTitle: '',

  recipient: { logo: '', name: '', address: '' },
  currency: undefined,
  allTokensBalances: [],

  sendCryptoData: {
    token: ECryptoCurrencySymbol.USDC,
    total: '',
    toAddress: '',
  },
  transactionUrl: '',
};

export const sendCryptoSlice = createSlice({
  name: 'sendCrypto',
  initialState,
  reducers: {
    // Goes back to the topmost status in history
    previousState: (state, action: PayloadAction<Partial<ISendCryptoState>>) => {
      const newHistory = [...state.statusHistory];
      const previousStatus = newHistory.pop() || ESendCryptoStatus.CHOOSE_RECIPIENT;
      return {
        ...state,
        ...action.payload,
        status: previousStatus,
        statusHistory: newHistory,
      };
    },
    // Skips the status in the history
    skipState: (state, action: PayloadAction<Partial<ISendCryptoState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    // Stores the status in the history
    nextState: (state, action: PayloadAction<Partial<ISendCryptoState>>) => {
      return {
        ...state,
        ...action.payload,
        statusHistory: [...state.statusHistory, state.status],
      };
    },
    // Update other information (like product response)
    updateState: (state, action: PayloadAction<Partial<ISendCryptoState>>) => {
      // Go to the next state

      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => initialState,
    resetSendCryptoState: (state) => {
      return {
        ...state,
        ...cloneDeep(initialState),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { previousState, skipState, nextState, updateState, reset, resetSendCryptoState } =
  sendCryptoSlice.actions;

export default sendCryptoSlice.reducer;
