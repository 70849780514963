import { useCallback, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

import { livemodeStorageKey } from '@/constants/config';
import { EMode } from '@/constants/enum';
import { queryClient } from '@/services/beamo/queryClient';
import { resetMerchantResolvedLock } from '@/services/firebaseAuth';
import { isDashboardModule } from '@/utils/apexModuleHelper';

export const getLivemode = (params = new URLSearchParams(location.search)) => {
  let livemode = true;
  const paramedLivemode = params.get('livemode');
  if (!paramedLivemode) {
    // Case 1: If the 'livemode' parameter is not present in the URL query string
    // and the current module is the dashboard module, check if there is a stored
    // 'livemode' value in the local storage. If the stored value is 'false',
    // set 'livemode' to false.
    if (isDashboardModule) {
      const storedLivemode = localStorage.getItem(livemodeStorageKey);
      if (storedLivemode === 'false') {
        livemode = false;
      }
    }
  } else {
    // Case 2: If the 'livemode' parameter is present in the URL query string,
    // check its value. If the value is 'false', set 'livemode' to false.
    // If the value is 'true', set 'livemode' to true.
    // If the current module is the dashboard module, store the 'livemode' value
    // in the local storage.
    if (paramedLivemode === 'false') {
      livemode = false;
    } else if (paramedLivemode === 'true') {
      livemode = true;
    }

    if (isDashboardModule) {
      localStorage.setItem(livemodeStorageKey, String(livemode));
    }
  }

  return livemode;
};

export const getEMode = () => {
  const livemode = getLivemode();
  return livemode === false ? EMode.TEST : EMode.LIVE;
};

// use singleton to share livemode state between components
export const useLivemode = singletonHook(
  () => {
    const livemode = getLivemode();
    return {
      livemode,
      setLivemode: () => {},
      mode: livemode === false ? EMode.TEST : EMode.LIVE,
    };
  },
  () => {
    const [livemode, _setLivemode] = useState(getLivemode());
    const setLivemode = useCallback(
      (mode: boolean) => {
        if (mode !== livemode) {
          const modeStr = String(mode);
          const params = new URLSearchParams(location.search);
          params.set('livemode', modeStr);
          history.replaceState(null, '', `?${params.toString()}`);
          _setLivemode(mode);
          if (isDashboardModule) {
            localStorage.setItem(livemodeStorageKey, modeStr);
            resetMerchantResolvedLock();
          }
          queryClient.invalidateQueries();
        }
      },
      [livemode]
    );
    return { livemode, setLivemode, mode: livemode === false ? EMode.TEST : EMode.LIVE };
  }
);
