import { useDispatch } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer as createSentryReduxEnhancer } from '@sentry/react';

import cashoutSwapReducer from '@/store/cashoutSwapSlice';
import connectReducer from '@/store/connectSlice';
import offrampReducer from '@/store/offrampSlice';
import sendCryptoReducer from '@/store/sendCryptoSlice';

import paymentAppGlobalReducer from './paymentAppGlobal';
import paymentDataReducer from './paymentDataSlice';
import paymentReducer from './paymentSlice';

export const _UNSAFE_RESET_STATE_ACTION = {
  type: '__UNSAFE_RESET_STATE_ACTION',
};

const sentryReduxEnhancer = createSentryReduxEnhancer({});

const reducerMap = {
  connect: connectReducer,
  offramp: offrampReducer,
  sendCrypto: sendCryptoReducer,
  cashoutSwap: cashoutSwapReducer,
  global: paymentAppGlobalReducer,
  payment: paymentReducer,
  paymentPageData: paymentDataReducer,
};

const appReducer = combineReducers(reducerMap);

export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === _UNSAFE_RESET_STATE_ACTION.type) {
      return appReducer({} as typeof state, action);
    }
    return appReducer(state, action);
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export type TRootState = ReturnType<typeof appReducer>;
export type TAppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch<TAppDispatch>;
