import { useEffect } from 'react';

export const useTitle = (title = 'Breeze') => {
  useEffect(() => {
    if (typeof document === 'undefined') return;

    const originalTitle = document.title;

    if (title) document.title = title;

    return () => {
      document.title = originalTitle;
    };
  }, [title]);
};
