import ReCAPTCHA from 'react-google-recaptcha';
import { AxiosResponse } from 'axios';

import { RECAPTCHA_KEY_V2 } from '@/constants/config';
import { logBreezeEvent } from '@/services/firebaseAuth';
import { overlayInstances, showOverlay } from '@/utils/overlayUtils';

import { setV2RecaptchaToken } from './recaptchaFallback';

/**
 * if v3 failed to verify, fallback to use v2
 */
function V2ReCaptcha({ onClose }: { onClose: () => void }) {
  const onV2ReCaptchaChange = async (token: string) => {
    if (!token) {
      return;
    }
    // console.debug('v2 token:', token);
    setV2RecaptchaToken(token);

    onClose();
  };

  return (
    <div className='absolute left-0 top-0 z-[9999] flex h-full w-full items-center justify-center bg-slate-50/75'>
      <ReCAPTCHA sitekey={RECAPTCHA_KEY_V2} onChange={onV2ReCaptchaChange} />
    </div>
  );
}

export function showV2ReCaptchaElement(response?: AxiosResponse<unknown, any>) {
  if (!overlayInstances.has('v2ReCaptcha')) {
    setV2RecaptchaToken('');

    if (response?.config) {
      logBreezeEvent('challenge', {
        requestUrl: response.config.url,
      });
    }
    showOverlay((close) => <V2ReCaptcha onClose={close} />, 'v2ReCaptcha');
  }
}
